<template>
  <div>
    <b-card header="LED限流电阻计算" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group append="V">
          <b-input-group-prepend is-text>
            电源电压 U<sub>E</sub>=
          </b-input-group-prepend>
          <b-form-input id="input_g1_ue" v-model="input_g1_ue" type="text" />
        </b-input-group>
        &nbsp;
        <b-input-group append="V">
          <b-input-group-prepend is-text>
            LED压降 U<sub>LED</sub>=
          </b-input-group-prepend>
          <b-form-input id="input_g1_uled" v-model="input_g1_uled" type="text" />
        </b-input-group>
        &nbsp;
        <b-input-group append="V">
          <b-input-group-prepend is-text>
            电阻分压 U<sub>R</sub>=
          </b-input-group-prepend>
          <b-form-input id="output_g1_ur" v-model="output_g1_ur" type="text" readonly />
        </b-input-group>
      </b-form>
      <br />
      <b-form inline>
        <b-input-group append="mA">
          <b-input-group-prepend is-text>
            工作电流 I=
          </b-input-group-prepend>
          <b-form-input id="input_g1_i" v-model="input_g1_i" type="text" />
        </b-input-group>
        &nbsp;
        <b-input-group>
          <b-input-group-prepend is-text>
            限流电阻 R<sub>L</sub>
          </b-input-group-prepend>
          <b-form-input id="output_rval" v-model="output_rval" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g1_runit" v-model="input_g1_runit">
              <option value="1" selected>Ω</option>
              <option value="1000">kΩ</option>
              <option value="1000000">MΩ</option>
              <option value="1000000000">GΩ</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b>常用LED压降参考（仅供参考，建议查阅数据手册）：</b>
      <b-table striped hover :items="uledRefData" :fields="uledRefFields"></b-table>
    </b-card>
  </div>
</template>
<script>
var uledRef = {
  fileds: [
    { key: "type", label: "种类" },
    { key: "min", label: "Min." },
    { key: "max", label: "Max." },
    { key: "typ", label: "Typ." },
    { key: "ref", label: "参考来源（立创商城物料号）" }
  ],
  items: [
    { type: "红色 0603", min: "1.9", max: "2.2", typ: "-", ref: "C2286" },
    { type: "黄色 0603", min: "1.7", max: "2.3", typ: "-", ref: "C72038" },
    { type: "翠绿 0603", min: "2.7", max: "3.7", typ: "3.3", ref: "C72043" },
    { type: "白色 0603", min: "2.7", max: "3.0", typ: "-", ref: "C2290" },
    { type: "蓝色 0603", min: "2.5", max: "3.1", typ: "-", ref: "C72041" },
    { type: "红色 0805", min: "1.6", max: "2.6", typ: "2.0", ref: "C84256" },
    { type: "黄色 0805", min: "2.0", max: "2.2", typ: "-", ref: "C2296" },
    { type: "蓝色 0805", min: "2.5", max: "3.6", typ: "3.0", ref: "C2293" }
  ]
};
export default {
  data() {
    return {
      input_g1_ue: "0",
      input_g1_uled: "0",
      input_g1_i: "0",
      input_g1_runit: "1",
      uledRefData: uledRef.items,
      uledRefFields: uledRef.fileds
    };
  },
  computed: {
    output_g1_ur() {
      return Number(this.input_g1_ue) - Number(this.input_g1_uled);
    },
    output_rval() {
      var ai = Number(this.input_g1_i) / 1000;
      var ar = this.output_g1_ur / ai;
      return ar / Number(this.input_g1_runit);
    }
  }
};
</script>