<template>
  <div>
    <b-card header="已知R" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group prepend="电阻 R=">
          <b-form-input id="input_rval" v-model="input_rval" type="text" />
          <b-input-group-append>
            <b-form-select id="input_runit" v-model="input_runit">
              <option value="1" selected>Ω</option>
              <option value="1000">kΩ</option>
              <option value="1000000">MΩ</option>
              <option value="1000000000">GΩ</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b-form inline>
        <b-input-group prepend="直流电压 U=" append="V">
          <b-form-input id="input_g1_u" v-model="input_g1_u" type="text" />
        </b-input-group>
        &nbsp;
        <b-input-group prepend="电流 I=">
          <b-form-input id="output_g1_i" v-model="output_g1_i" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g1_iunit" v-model="input_g1_iunit">
              <option value="0.001">kA</option>
              <option value="1" selected>A</option>
              <option value="1000">mA</option>
              <option value="1000000">μA</option>
              <option value="1000000000">nA</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="功率 P=">
          <b-form-input id="output_g1_p" v-model="output_g1_p" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g1_punit" v-model="input_g1_punit">
              <option value="0.000000001">GW</option>
              <option value="0.000001">MW</option>
              <option value="0.001">kW</option>
              <option value="1" selected>W</option>
              <option value="1000">mW</option>
              <option value="1000000">μW</option>
              <option value="1000000000">nW</option>
              <option value="0.001341">HP(英制马力)</option>
              <option value="0.00136">PS(公制马力)</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b-form inline>
        <b-input-group prepend="直流电流 I=">
          <b-form-input id="input_g2_i" v-model="input_g2_i" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g2_iunit" v-model="input_g2_iunit">
              <option value="0.001">kA</option>
              <option value="1" selected>A</option>
              <option value="1000">mA</option>
              <option value="1000000">μA</option>
              <option value="1000000000">nA</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="压降 U=" append="V">
          <b-form-input id="output_g2_u" v-model="output_g2_u" type="text" readonly />
        </b-input-group>
        &nbsp;
        <b-input-group prepend="功率 P=">
          <b-form-input id="output_g2_p" v-model="output_g2_p" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g2_punit" v-model="input_g2_punit">
              <option value="0.000000001">GW</option>
              <option value="0.000001">MW</option>
              <option value="0.001">kW</option>
              <option value="1" selected>W</option>
              <option value="1000">mW</option>
              <option value="1000000">μW</option>
              <option value="1000000000">nW</option>
              <option value="0.001341">HP(英制马力)</option>
              <option value="0.00136">PS(公制马力)</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b-form inline>
        <b-input-group prepend="时间 t=">
          <b-form-input id="input_g3_t" v-model="input_g3_t" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g3_tunit" v-model="input_g3_tunit">
              <option value="0.000000000001">ps</option>
              <option value="0.000000001">ns</option>
              <option value="0.000001">μs</option>
              <option value="0.001">ms</option>
              <option value="1" selected>s</option>
              <option value="60">min</option>
              <option value="3600">h</option>
              <option value="86400">day</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="手动输入功率 P=">
          <b-form-input id="input_g3_pmi" v-model="input_g3_pmi" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g3_pmiunit" v-model="input_g3_pmiunit">
              <option value="0.000000001">GW</option>
              <option value="0.000001">MW</option>
              <option value="0.001">kW</option>
              <option value="1" selected>W</option>
              <option value="1000">mW</option>
              <option value="1000000">μW</option>
              <option value="1000000000">nW</option>
              <option value="0.001341">HP(英制马力)</option>
              <option value="0.00136">PS(公制马力)</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <br />
      <b-form inline>
        <b-input-group prepend="功率 P=">
          <b-input-group-append>
            <b-form-select id="input_g3_psel" v-model="input_g3_psel">
              <option :value="output_g1_p_w" selected>以上由电压计算所得 {{output_g1_p_w}} W</option>
              <option :value="output_g2_p_w">以上由电流计算所得 {{output_g2_p_w}} W</option>
              <option :value="output_g3_pmi_w">手动输入 {{output_g3_pmi_w}} W</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="功 W=">
          <b-form-input id="output_g3_w" v-model="output_g3_w" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g3_wunit" v-model="input_g3_wunit">
              <option value="0.001">kJ</option>
              <option value="1" selected>J</option>
              <option value="0.00027777778">W·h</option>
              <option value="0.00000027777778">kW·h</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input_rval: "0",
      input_runit: "1",
      input_g1_u: "0",
      input_g1_iunit: "1",
      input_g1_punit: "1",
      input_g2_i: "0",
      input_g2_iunit: "1",
      input_g2_punit: "1",
      input_g3_t: "0",
      input_g3_tunit: "1",
      input_g3_pmi: "0",
      input_g3_pmiunit: "1",
      input_g3_psel: "0",
      input_g3_wunit: "1"
    };
  },
  computed: {
    output_g1_i() {
      var ar = Number(this.input_rval) * Number(this.input_runit);
      var au = Number(this.input_g1_u);
      var ai = au / ar;
      return ai * Number(this.input_g1_iunit);
    },
    output_g1_p_w() {
      var ar = Number(this.input_rval) * Number(this.input_runit);
      var au = Number(this.input_g1_u);
      var ap = (au * au) / ar;
      return ap;
    },
    output_g1_p() {
      return this.output_g1_p_w * Number(this.input_g1_punit);
    },
    output_g2_u() {
      var ar = Number(this.input_rval) * Number(this.input_runit);
      var ai = Number(this.input_g2_i) / Number(this.input_g2_iunit);
      return ar * ai;
    },
    output_g2_p_w() {
      var ar = Number(this.input_rval) * Number(this.input_runit);
      var ai = Number(this.input_g2_i) / Number(this.input_g2_iunit);
      var ap = ai * ai * ar;
      return ap;
    },
    output_g2_p() {
      return this.output_g2_p_w * Number(this.input_g2_punit);
    },
    output_g3_pmi_w() {
      return Number(this.input_g3_pmi) * Number(this.input_g3_pmiunit);
    },
    output_g3_w() {
      var at = Number(this.input_g3_t) * Number(this.input_g3_tunit);
      var aw = this.input_g3_psel * at;
      return aw * Number(this.input_g3_wunit);
    }
  }
};
</script>