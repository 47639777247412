<template>
  <div>
    <BO001 />
    <br />
    <BO002 />
  </div>
</template>
<script>
import BO001 from "@/views/submods/basic_ohms/bo001.vue"
import BO002 from "@/views/submods/basic_ohms/bo002.vue"
export default {
  components: { BO001, BO002 }
}
</script>